import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import Layout from '../components/Layout';
import Section from '../components/Section';
import Pagination from '../components/Pagination';
import Caption from '../components/Caption';
import Split from '../components/Split';

const VineyardPage = ({ data }) => {
  return (
    <Layout>
      <Section heading="What's happening in our vineyard, by Sam Middleton, Head Winemaker">
        <Split>
          <Img fluid={data.image.childImageSharp.fluid} />
          <p>
            As most of you are aware Phylloxera was first discovered in the Yarra
            Valley in 2006. To his great credit David did not waste any time when
            he acquired the neighbouring, south facing paddock in 2007 and began
            planting vines grafted on resistant rootstocks in 2008. It is for
            this reason that we have rootstock vineyards now in excess of 10 years of
            age (in fact our first rootstock vineyard was a block of Merlot planted in
            2003, 3 years before any Phylloxera was detected in the valley. This block
            is now 16 years old!). Our goal back then was to convert, as quickly as
            possible, at least half of our vineyard area onto phylloxera resistant
            rootstock, without the removal of any of our exceptionally important old
            vine, own rooted blocks. By 2021 we will have achieved this.
          </p>
        </Split>
        <p>
          It is a blessing we still have no known phylloxera detections on our
          site and we will continue to work extremely hard to ensure we stay
          this way for as long as possible. Due to our isolation from other
          vineyards, being self- contained with no external machinery entering
          our property combined with strict farm gate protocols we believe we
          can remain phylloxera free for longer than most in the valley. The
          need to replant has given us a great opportunity to assess where
          certain varieties are best suited for planting, along with increasing
          clonal diversity across our vineyard. Our focus has been in moving
          our Pinot and Chardonnay onto the cooler south facing slopes while
          continuing to plant our northern facing sites with our flagship
          Quintet varieties.
        </p>
        <Img fluid={data.vineyardImage.childImageSharp.fluid} />
        <Caption>Our multi-clonal close planted Pinot noir vineyard</Caption>
      </Section>
      <Pagination
        forwardLink="/jwdm"
        backLink="/team"
      />
    </Layout>
  );
};

VineyardPage.propTypes = {
  data: PropTypes.shape({
    image: PropTypes.object,
  }),
};

export const query = graphql`
  query {
    image: file(relativePath: { eq: "sam-harvest.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    vineyardImage: file(relativePath: { eq: "north-vineyard.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default VineyardPage;
